<!-- <div class="box-wrapper" fxLayoutAlign="center center">
    <div class="mission-box" [ngStyle]="!!mission.thumbnailMobile && {'background-image': getImageUrl()}" [style]="mission.missionType === 2?{'filter':'saturate(0.2)'}:''" [ngClass]="(isDesktop && !isModalDisplay) ? 'desktop': 'mobile'" (click)="checkAndOpen()" fxLayoutAlign="space-between center">
        <div class="info" fxFlex="56" fxLayout="column" fxLayoutAlign="center space-between">
            <div>
                <img *ngIf="mission.missionType === 0" class="live-icon" src="assets/mobile/live-icon-small-mobile.svg">
                <div class="label" [innerHTML]="mission.name"></div>
            </div>
            <ng-container *ngIf="!isFinished else finished">

                <div *ngIf="mission.missionType === 0" class="time">
                    <img class="hour-glass" src="assets/mobile/hour-glass.svg">
                    <ng-container *ngIf="tournamentStartDateLocal<=now">
                        <div #tournamentstartdate></div>
                    </ng-container>
                    <ng-container *ngIf="tournamentStartDateLocal>now">
                        {{ tournamentStartDateLocal | date : 'short':'':locale
                        }}<br />
                        <span i18n>Starting soon</span>
                    </ng-container>
                </div>
            </ng-container>
            <div *ngIf="mission.missionType === 1" class="time">{{ tournamentStartDateLocal | date : 'short':'':locale
                }}
            </div>
            <div class="dynamic-text" *ngIf="mission.missionType === 1 || mission.missionType === 0">{{game}}</div>
            <div *ngIf="mission.missionType === 2">
                <div i18n>Finished</div>
                <div class="time">{{ tournamentEndDateLocal | date : 'short':'':locale}}</div>
            </div>
        </div>
    </div>
</div>

<ng-template #finished>
    <div i18n>Finished</div>
</ng-template> -->

<!-- latest -->

<div class="box-wrapper" fxLayoutAlign="center center">
    <div class="tournament-box" [ngStyle]="!!mission.thumbnailMobile && {'background-image': getImageUrl()}" [ngClass]="(isDesktop && !isModalDisplay) ? 'desktop': 'mobile'" (click)="checkAndOpen()" fxLayoutAlign="space-between center">
        <div class="info" fxFlex="56" fxLayout="column" fxLayoutAlign="space-between">
            <!-- <div>
                <img *ngIf="tournament.missionType === 0" class="live-icon" src="assets/mobile/badge-icon-0.svg">
                <img *ngIf="tournament.missionType === 1" class="live-icon" src="assets/mobile/badge-icon-1.svg">
                <img *ngIf="tournament.missionType === 2" class="live-icon" src="assets/mobile/badge-icon-2.svg">
                <img *ngIf="tournament.isPrivate" class="live-icon" src="assets/mobile/locked.svg.svg">
            </div> -->
            <div class="badge-container-on" *ngIf="mission.isPrizeEngineEligiblePlayers === true && isLogged else others">
                <img class="live-icon" src="assets/reactivation/mission/Vector.svg">
                <span i18n>ON</span>
            </div>
            <ng-template #others>
                <div class="badge-container batch-bg-0" *ngIf="mission.missionType === 0">
                    <img class="live-icon" src="assets/icons/missions-active-icon.svg" alt="missions-active-icon">
                    <span i18n>Active</span>
                </div>
                <div class="badge-container batch-bg-1" *ngIf="mission.missionType === 1">
                    <img class="live-icon" src="assets/mobile/badge-icon-1.svg" alt="badge-icon-1">
                    <span i18n>Starts soon!</span>
                </div>
            </ng-template>
            <div>
                <div class="label" [innerHTML]="mission.name | safeHtml"></div>
            </div>
            <ng-container>
                <div class="wrapper" *ngIf="mission.missionType === 0">
                    <div class="time">
                        <img class="hour-glass" src="assets/mobile/hour-glass.svg">
                        <ng-container *ngIf="tournamentStartDateLocal<=now">
                            <div #tournamentstartdate></div>
                        </ng-container>
                        <ng-container *ngIf="tournamentStartDateLocal>now">
                            {{ tournamentStartDateLocal | date : 'short':'':locale
                            }}<br />
                            <span i18n>Starting soon</span>
                        </ng-container>
                    </div>
                    <div class="dynamic-text">{{game}}</div>
                </div>
            </ng-container>
            <div class="wrapper" *ngIf="mission.missionType === 1">
                <div class="time">
                    <img class="hour-glass" src="assets/mobile/hour-glass.svg">
                    {{ tournamentStartDateLocal | date : 'short':'':locale
                    }}
                </div>
                <div class="dynamic-text">{{game}}</div>
            </div>
        </div>
    </div>
</div>
