<div *ngFor="let category of data; let first= first" class="game-category-container" [ngStyle]="{'margin-top': !first ? '13px' : '13px'}">
    <div class="categoryNameContainer" fxLayout="row" fxLayoutAlign="center center">

        <h2 fxLayoutAlign="center center" class="category-title">
            <img class="category-icon" src="{{category?.icon?.url}}" *ngIf="category.icon!==null else actual">
            <span [innerHTML]="category.displayName | removehtml"></span>
        </h2>
        <span class="spacer"></span>
        <a class="button-all-games" fxLayoutAlign="end center" fxLayout="row" (click)="routerClicked()" [routerLink]="category.link">
            <span i18n>All</span>
            <!-- <div> -->
                <img src="{{licenceService.getKey('arrow-icon')}}" icon-btn size="large">
            <!-- </div> -->
        </a>

    </div>
    <app-front-page-games [data]="category" [rowsCount]="category.numberGameRows ?? 2"></app-front-page-games>
</div>
<ng-template #actual>
    <img class="category-icon" src="assets/desktop/top-games-icon.svg">
</ng-template>
