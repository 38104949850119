import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-close-dialog-button',
  templateUrl: './close-dialog-button.component.html',
  styleUrls: ['./close-dialog-button.component.scss']
})
export class CloseDialogButtonComponent implements OnInit {

  constructor(private route:ActivatedRoute) { }

  @Output() closeEvent = new EventEmitter<string>();
  @Input() customClass: string;
  @Input() position: boolean;

  callLinkClickedParent(url?: string): void {
    var redirect = this.route.snapshot.queryParams['redirectTo'];
    if (url) this.closeEvent.next(url);
    else if(redirect) this.closeEvent.next(redirect);
    else this.closeEvent.next('/')
  }

  ngOnInit(): void {
  }

}
