import { Component } from '@angular/core';
import { takeUntil } from 'rxjs';
import {
  aboutTrx,
  arcadeTrx,
  aviatorTrx,
  clientAreaMainTrx,
  clientAreaTrx,
  desktopTrx,
  gameInfoTrx,
  gameTrx,
  liveCasinoTrx,
  lostPasswordTrx,
  promotionsTrx,
  termsTrx
} from 'src/app/router-translation.labels';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { LoggedStatus } from 'src/app/services/auth/login/login.models';
import { MinimizeSiteService } from 'src/app/services/utils/hide-footer.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { BasePageComponent } from '../../base-page/base-page.component';
import { HidePromotionsService } from 'src/app/services/utils/hide-promotions.service';

@Component({
  selector: 'app-desktop-top-menu',
  templateUrl: './desktop-top-menu.component.html',
  styleUrls: ['./desktop-top-menu.component.scss']
})
export class DesktopTopMenuComponent extends BasePageComponent {

  constructor(
    protected loginStatus: LoginStatusService,
    protected translationConfig: TranslationConfig,
    public minimizeSiteService: MinimizeSiteService,
    public bannerService: HidePromotionsService,
  ) { super() }

  isLogin: boolean = false;

  lostPasswordTrx = this.translationConfig.getTranslation(lostPasswordTrx);
  aboutTrx: string = this.translationConfig.getTranslation(aboutTrx);
  termsTrx: string = this.translationConfig.getTranslation(termsTrx);
  promotionsTrx: string = this.translationConfig.getTranslation(promotionsTrx);
  prifileTrx: string = this.translationConfig.getTranslation(clientAreaTrx) + '/' + this.translationConfig.getTranslation(clientAreaMainTrx)
  liveCasinoUrl: string = this.translationConfig.getTranslation(liveCasinoTrx);
  arcadeUrl: string = this.translationConfig.getTranslation(gameTrx) + '/' + this.translationConfig.getTranslation(arcadeTrx);
  aviatorUrl:string = this.translationConfig.getTranslation(gameInfoTrx) + '/' + this.translationConfig.getTranslation(aviatorTrx);
  slots: string = this.translationConfig.getTranslation(gameTrx);
  isInGame: boolean = false;
  desktopTrx = this.translationConfig.getTranslation(desktopTrx);

  ngOnInit(): void {
    this.loginStatus.getLoginStatus().pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
      this.isLogin = resp.isLogged === LoggedStatus.logged ? true : false;
    })

    this.minimizeSiteService.isRegularPage().pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
      this.isInGame = resp;
    })

  }

  toggleBanner() {
    this.bannerService.toggleShowBanner();
  }

}
