<app-mobile-page-back-header iconName="pay-history.svg"><ng-container i18n>payment history</ng-container></app-mobile-page-back-header>
<div class="payment-body" [ngClass]="isDesktop ? 'desktop': 'mobile'" fxLayoutAlign="start center" fxLayout="column">
  <div class="wrapper">
    <div *ngIf="isDesktop" class="payment-header">
      <div *ngIf="isDeposit" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="8">
        <div class="header-icon">
          <img src="assets/icons/icon-arrow-deposit.svg" alt="icon-deposit">
        </div>
        <div class="header-text" i18n>Deposits</div>
      </div>
      <div *ngIf="!isDeposit" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="8">
        <div class="header-icon">
          <img src="assets/icons/icon-arrow-withdrawal.svg" alt="icon-deposit">
        </div>
        <div class="header-text" i18n>Withdrawals</div>
      </div>
    </div>
    <nav mat-tab-nav-bar class="payment-nav-bar" color="accent">
      <a class="payment-tab-link" mat-tab-link [active]="isDeposit === true" routerLinkActive="active" (click)="switchToDeposit()">
        <div class="select-tab-container" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="3px">
          <div class="icon">
            <img src="assets/icons/icon-arrow-deposit.svg" alt="icon-deposit">
          </div>
          <div class="text" i18n>Deposits</div>
        </div>
      </a>
      <a class="payment-tab-link" mat-tab-link [active]="isDeposit === false" routerLinkActive="active" (click)="switchToWithdrawal()">
        <div class="select-tab-container" fxLayoutAlign="center center" fxLayout="row" fxLayoutGap="3px">
          <div class="icon">
            <img src="assets/icons/icon-arrow-withdrawal.svg" alt="icon-deposit">
          </div>
          <div class="text" i18n>Withdrawals</div>
        </div>
      </a>
    </nav>
  </div>
</div>
<div class="results" *ngIf="pageData?.length >0 else nothing">
  <div class="list" fxLayout="column" fxLayoutGap="4px">
    <div class="loop" *ngFor="let item of pageData;let last = last">
      <div class="entry" (click)="paymentAction(item)" fxLayout="row" fxLayoutAlign="space-between center">
        <div class="item" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
          <div class="logo">
            <div style="display: flex;">
              <img src="assets/icons/{{getIconName(item)}}.svg" />
            </div>
          </div>
          <div class="name-group">
            <div class="type" fxLayout="column" fxLayoutGap="4px">
              <div class="date">
                {{item.date | slice:0:10}}
              </div>
              <div class="status">
                {{item.get_status_display}}
              </div>
            </div>
          </div>
        </div>
        <div class="item-tail">
          <div class="tail-group">
            <div class="amount h16-22">
              {{item.transaction_value}} {{item.transaction_currency}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pagination">
    <div class="buttons">
      <div (click)="previousPage()" [class.button-icon-only-back]="hasPreviousPage" [class.button-icon-only-back-disabled]="!hasPreviousPage">
        <div class="chevron-left">
          <img class="icon" src="assets/desktop/yellow-arrow.svg" alt="yellow-arrow">
        </div>
      </div>
      <div class="range-text">{{(currentPage-1)*10+1}} - {{currentPage*10 < itemsCount ? currentPage*10 : itemsCount }} / {{itemsCount}}</div>
      <div (click)="nextPage()" [class.button-icon-only-forward]="hasNextPage" [class.button-icon-only-forward-disabled]="!hasNextPage">
        <div class="chevron-right">
          <img class="icon" src="assets/desktop/yellow-arrow.svg" alt="yellow-arrow">
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #nothing>
  <div *ngIf="!isLoading">
    <div *ngIf="isDeposit" class="no-list" i18n>There are no payments.</div>
    <div *ngIf="!isDeposit" class="no-list" i18n>There are no withdrawals.</div>
  </div>
  <div *ngIf="isLoading">
    <div *ngIf="isDeposit" class="no-list"><span style="text-transform: capitalize;" i18n>loading</span>...</div>
  </div>
</ng-template>
