import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MinimizeSiteService {

  constructor() { }

  private _showFooterAndHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _showFooter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _showToolbar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _showAll: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  isRegularPage() {
    return this._showFooterAndHeader.asObservable().pipe(
      debounceTime(0),
    );
  }

  isFooterNotMinimized() {
    return this._showFooter.asObservable();
  }

  isToolbarNotMinimized() {
    return this._showToolbar.asObservable();
  }

  isAllNotMinimized() {
    return this._showAll.asObservable();
  }

  public minimizePage() {
    this._showFooterAndHeader.next(false);
    this._showFooter.next(false);
  }


  public restorePage() {
    this._showFooterAndHeader.next(true);
    this._showFooter.next(true);
  }

  public minimizeFooter() {
    this._showFooter.next(false);
  }

  public restoreFooter() {
    this._showFooter.next(true);
  }

  public minimizeToolbar() {
    this._showToolbar.next(false);
  }

  public restoreToolbar() {
    this._showToolbar.next(true);
  }

  public minimizeAll() {
    this._showAll.next(false);
  }

  public restoreAll() {
    this._showAll.next(true);
  }
}
