<div class="menu" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="category-menu-wrapper" fxLayout="row" fxLayoutAlign="start center">
        <!-- <div class="category" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Home</div> -->
        <div *ngFor="let category of data?.slice(0,8); let first= first" class="category" [ngClass]="{'first':  first}" [routerLink]="['/'+onCategoryClick(category)]" routerLinkActive="active" [innerHTML]="category.displayName | removehtml"></div>
    </div>
    <div class="search" fxLayout="row" fxLayoutGap="7px" fxLayoutAlign="end center">
        <div>
            <div class="search-content"  *ngIf="showInput">
                <input type="text" [placeholder]="searchPlaceHolder" [formControl]="searchInput" (keyup.enter)="emitSearch(searchInput.value)">
                <img src="assets/desktop/search-in-old.svg" alt="icon-search" (click)="emitSearch(searchInput.value)" />
            </div>
            <img icon-btn filled="filled" size="large" src="assets/desktop/search-in.svg" alt="icon-search" [routerLink]="routeGame" *ngIf="!showInput" />
        </div>
        <!-- <div class="search-content filter">
            <img src="assets/desktop/filter-icon.svg" alt="icon-filter" />
        </div> -->
    </div>
</div>