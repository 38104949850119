<ng-container *ngIf="!isDesktop else desktop">
  <ng-container *ngIf="!!gamificationIndex && gamificationIndex>-0">
    <div *ngIf="isLogin" class="profile-tool mobile" fxLayout="row" fxLayoutAlign="center center">
      <div class="profile-container mobile" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <div class="content mobile-top" fxLayoutAlign="space-between start" fxLayout="row">
          <div class="profile-name" fxLayoutAlign="center start" fxLayout="column">
            <div class="thumbnail mobile-top" (click)="redirectToProfile()">
              <ng-container>
                <img src="assets/icons/lemons-levels/icon-lemon-level-{{gamificationIndex}}.svg" alt="icon-gamification" />
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #desktop>
  <div *ngIf="isLogin" class="profile-tool desktop" fxLayout="row" fxLayoutAlign="center center">
    <div class="profile-container" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
      <app-profile-info size="desktop-top"></app-profile-info>
      <hr class="vertical-line" fxFlex="none" fxOrder="3" fxLayoutAlign="start" />
      <div class="item" fxLayout="column" fxLayoutAlign="start center" [routerLink]="['/'+inboxUrl]">
        <img src="assets/icons/icon-inbox-sticky.svg" alt="inbox" />
        <div *ngIf="unreadMessages > 0" class="notification-circle">
          <span class="notification-number">{{unreadMessages}}</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>