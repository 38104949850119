<div (click)="onFilterChoose(filterItem)" class="filter-selector-wrapper"> <!-- global style -->
    <div class="filter-selector">
        <div class="filter-selector-title">
            <ng-container *ngIf="filterItem; else loading">
                <p>{{filterItem.displayName}}</p>
            </ng-container>

            <ng-template #loading>
                <p i18n>Loading...</p>
            </ng-template>
        </div>
        <div class="filter-selector-action">
            <div *ngIf="filterItem.activeFilters !== 0" class="count-container">
                <p>{{filterItem.activeFilters}}</p>
            </div>
            <div class="filter-selector-icon"> <!-- [class.rotate]="isSelected" -->
                <img src="assets/icons/icon-arrow-down.svg" alt="icon-arrow-down" class="s">
            </div>
        </div>
    </div>
</div>