import { environment as common } from './environment.prod';


export const environment = {
  ...common,
  production: false,
  apiUrl: 'https://api-test.lemon.casino',
  walletUrl: 'https://api-test.lemon.casino',
  paymentUrl: 'https://api-test.lemon.casino',
  uiBackend: 'https://prizeengine-staging.xtech.bet',
  baseCurrency: "EUR",
  fallbackLanguage: "en",
  enableServiceWorker: false,
  enableTournamentsDisplay: true,
  enablePaymentIQ: true,
  affiliateProgram: 'https://lemon.partners/',
  cmsApi: 'https://eu-central-1-lemon-casino.cdn.hygraph.com/content/clac9rrgn4tb401uma47z2n9n/master',
  cmsDraftToken: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2NjgxNTcyODEsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEtbGVtb24tY2FzaW5vLmh5Z3JhcGguY29tL3YyL2NsYWM5cnJnbjR0YjQwMXVtYTQ3ejJuOW4vbWFzdGVyIiwibWFuYWdlbWVudC1uZXh0LmdyYXBoY21zLmNvbSJdLCJpc3MiOiJodHRwczovL21hbmFnZW1lbnQuZ3JhcGhjbXMuY29tLyIsInN1YiI6ImZhNDA0NmZmLTJkZDctNDA0Yi05MDk0LTZhN2JhNDBiOWUyZCIsImp0aSI6ImNrbmlyc2lvcTJpY2IwMXhuYTl4YzFqbjYifQ.Y8rCNSyFBZjXGqhRml9934h38N-bzQ8JKHmMEIqdI3qaDPyhk0aTTcg5PDIz8ngOMf3nP7pIqv7af5Qja897A_tm6kR9enT8A_Rf11T8p3v50_ZFTStD7mkjZbHlPBr-eV-02to3POLXKkp9hG7-A7Hh6-Lb492xh171TIIISTNMgsT5k98PW_mwSo_I1_Z0pF1jBXiScG-pbKWMn50pz7_Blmopi0pRm1Ar0BjyAc9kw_ZZmv6Xiks-5Z2F0Za80SECNGLnrIFlltMwtzbPCrB06PbrxrPKWoLHjyKdP7H342nxNVCR_H9L4YYLZzbJIx15ncfZ04ZP7WLnBS662l0dyVvW23fh_kX-KEfCYDXRe7EpVSGG0U8ZMzn8p_3VN7V83DK5rMQpvRXVkKrHIJfGPNngO9GLW7br3j5VWx-p6BIKop700zdMXR2FgVFlG1v7crTxgMUpXZViqj0uT7T16ybWPjSHS9URiKgcDctumfTk6ZTM6t-JtliS5lSVJYB6g4bsyc_zv-JvjSKtnSZOfpwo6Qr-fsqb_n7nA_zgNqLIt-A43ZTSwn7VlhDvSCggZf12oCmli-cU3tXTHDVOdTAMtDIhkaqTh6J4btbfYOSyf5L9By-1JZ01aG9eeMJsRHwPNYRZGXtEe8YlaZZcs5OvnXsxZcm4gl0gG2o",
  firebaseConfig: {
    apiKey: "AIzaSyDXHm7r8G_KipeE79bitZxXiWevzBcb2hI",
    authDomain: "sportsbetting-prod.firebaseapp.com",
    databaseURL: "https://sportsbetting-prod.firebaseio.com",
    projectId: "sportsbetting-prod",
    storageBucket: "sportsbetting-prod.appspot.com",
    messagingSenderId: "621761546990",
    appId: "1:621761546990:web:06887218cbebaed43481ff"
  },
  paymentIqConfig: {
    url: 'https://test-api.paymentiq.io/paymentiq/api',
    mid: 100539999,
    sessionIdName: 'citrin_ses',
    userPrefix: 'user'
  },
  checkSessionInterval: 5000,
  checkInboxInterval: 30000,
  checkPopularGamesUsersInterval: 60000,
  minBalanceThresholds: {
    USD: 23,
    EUR: 20,
    GBP: 17,
    PLN: 80,
    HUF: 6000,
    INR: 1500,
    CAD: 30,
    AUD: 32,
    NZD: 35,
    ZAR: 350,
    NOK: 200
  },
  defaultDepositValues: {
    PLN: [50, 100, 200, 500, 1000],
    USD: [20, 50, 100, 200, 500],
    EUR: [20, 50, 100, 200, 500],
    GBP: [20, 50, 100, 200, 500],
    HUF: [5000, 10000, 20000, 50000, 100000],
    INR: [500, 1000, 2000, 5000, 10000],
    CAD: [20, 50, 100, 200, 500],
    AUD: [20, 50, 100, 200, 500],
    NZD: [20, 50, 100, 200, 500],
    ZAR: [150, 300, 500, 1000, 2000, 5000],
    NOK: [200, 500, 1000, 2000, 5000]
  }
};
