import { Component, ElementRef, HostListener, Input, OnInit, Output, ViewChild, AfterViewInit, TemplateRef, Renderer2, EventEmitter } from '@angular/core';
import { Subscription, takeUntil } from 'rxjs';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';

export interface SwiperConfig {
  width: string;      // e.g., '100%' or '300px'

  swiperContainerHeight: string;     // e.g., '100%' or '200px'
  swiperWrapperHeight: string;
  alignItems: string;

  gap: string;  //
  loop: boolean;

  swipeToMove?: number;
}

@Component({
  selector: 'app-search-game-swiper',
  templateUrl: './search-game-swiper.component.html',
  styleUrls: ['./search-game-swiper.component.scss']
})
export class SearchGameSwiperComponent extends BasePageComponent implements OnInit, AfterViewInit {

  @ViewChild('swiperContainer') swiperContainer!: ElementRef;
  @ViewChild('swiperWrapper') swiperWrapper!: ElementRef;
  @ViewChild('swiperWrapper', { read: ElementRef }) swiperWrapperElement!: ElementRef;

  private swiperConfig: SwiperConfig;
  @Input() _swiperConfig: SwiperConfig;

  @Input() swipeToFrontEvent: EventEmitter<{ id: number }>;
  @Input() swipeToBackEvent: EventEmitter<{ id: number }>;
  @Input() id: number;

  private subscriptions: Subscription = new Subscription();

  @Output() slideClick = new EventEmitter<any>();

  private totalSlidesWidth = 0;
  private slidesWidth: number[] = [];

  private requestAnimationFrameId: number;
  private isDragging = false;
  private startX = 0;
  private delta = 0;
  public translate = 0;

  private mouseDownTime = 0;
  private readonly maxClickDuration = 200;
  private debounceTimeout: any;

  private currentSlide = 0;
  private slideAmount = 0;

  constructor(
    private renderer: Renderer2,
  ) {
    super()
  }

  ngOnInit() {
    this.swiperConfig = this._swiperConfig;

    if (this.swipeToFrontEvent) {
      this.subscriptions.add(this.swipeToFrontEvent.subscribe((event) => {
        if (event.id === this.id) {
          this.swipeToFront();
        }
      }));
    }
    
    if (this.swipeToBackEvent) {
      this.subscriptions.add(this.swipeToBackEvent.subscribe((event) => {
        if (event.id === this.id) {
          this.swipeToBack();
        }
      }));
    }
    
  }

  ngAfterViewInit() {
    this.setConfig(this.swiperConfig);
    this.checkImagesLoaded();
  }

  /* ------------------------------------------------------------------ */

  onMouseDown(event: MouseEvent) {
    this.isDragging = true;
    this.startX = event.pageX;
    this.mouseDownTime = Date.now();

    this.renderer.setStyle(this.swiperWrapper.nativeElement, 'cursor', 'grabbing');
    this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transition', 'none');

    this.requestAnimationFrameId = requestAnimationFrame(() => this.processMouseMove());
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.isDragging) return;
    this.delta = event.pageX - this.startX;
    this.translate += this.delta;
    this.startX = event.pageX;

    if (this.requestAnimationFrameId) {
      cancelAnimationFrame(this.requestAnimationFrameId);
    }

    this.requestAnimationFrameId = requestAnimationFrame(() => this.processMouseMove());
  }

  moveLastSlideToFront() {
    const lastSlide = this.swiperWrapper.nativeElement.lastElementChild;
    this.renderer.insertBefore(this.swiperWrapper.nativeElement, lastSlide, this.swiperWrapper.nativeElement.firstElementChild);
  }

  moveFirstSlideToEnd() {
    const firstSlide = this.swiperWrapper.nativeElement.firstElementChild;
    this.renderer.appendChild(this.swiperWrapper.nativeElement, firstSlide);
  }

  processMouseMove() {
    const maxTranslate = -(this.totalSlidesWidth - this.swiperContainer.nativeElement.clientWidth );

    if (this.translate >= 0) {
      this.translate = 0;

      if (this.swiperConfig.loop) {
        this.translate -= this.slidesWidth[this.slidesWidth.length - 1];
        this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);

        this.moveLastSlideToFront();

        this.slidesWidth.unshift(this.slidesWidth.pop() as number);
      } 

    } else if (this.translate <= maxTranslate) {
      this.translate = maxTranslate;

      if (this.swiperConfig.loop) {
        this.translate += this.slidesWidth[0];
        this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);

        this.moveFirstSlideToEnd();

        this.slidesWidth.push(this.slidesWidth.shift() as number);
      }
    }

    // if (this.translate < -(this.slidesWidth.slice(0, this.currentSlide + 1).reduce((acc, val) => acc + val, 0)) + parseInt(this.swiperConfig.gap) * this.currentSlide + 1 ) {
    //   this.currentSlide++;
    //   console.log(this.currentSlide);
    // } else if (this.translate > -(this.slidesWidth.slice(0, this.currentSlide).reduce((acc, val) => acc + val, 0)) + parseInt(this.swiperConfig.gap) * this.currentSlide + 1 ) {
    //   this.currentSlide--;
    //   console.log(this.currentSlide);
    // }

    const currentTranslate = -this.translate;
    const totalSlideWidth = (index: number) => this.slidesWidth.slice(0, index + 1).reduce((acc, val) => acc + val, 0) + parseInt(this.swiperConfig.gap) * (index + 1);

    if (currentTranslate >= totalSlideWidth(this.currentSlide)) {
      this.currentSlide++;
    } else if (currentTranslate < totalSlideWidth(this.currentSlide - 1)) {
      this.currentSlide--;
    }

    this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);
  }

  @HostListener('window:mouseup')
  onMouseUp() {
    this.isDragging = false;

    if (this.requestAnimationFrameId) {
      cancelAnimationFrame(this.requestAnimationFrameId);
    }

    const mouseUpTime = Date.now();
    if (mouseUpTime - this.mouseDownTime < this.maxClickDuration) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(() => {
        console.log('emit');
        this.slideClick.emit();
      }, 200);

    }

    this.renderer.setStyle(this.swiperWrapper.nativeElement, 'cursor', 'grab');
    this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transition', 'none');
  }

  @HostListener('window:mouseleave')
  onMouseLeave() {
    this.isDragging = false;

    if (this.requestAnimationFrameId) {
      cancelAnimationFrame(this.requestAnimationFrameId);
    }

    this.renderer.setStyle(this.swiperWrapper.nativeElement, 'cursor', 'grab');
    this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transition', 'none');
  }

  /* ------------------------------------------------------------------ */

  private moveItemsToFront(count: number) {
    for (let i = 0; i < count; i++) {
      this.moveLastSlideToFront();
      this.slidesWidth.unshift(this.slidesWidth.pop()!);
    }
  }

  private moveItemsToBack(count: number) {
    for (let i = 0; i < count; i++) {
      this.moveFirstSlideToEnd();
      this.slidesWidth.push(this.slidesWidth.shift()!);
    }
  }

  swipeToFront() {
    if (this.swiperConfig.swipeToMove) {
      const endSlide = this.currentSlide - this.swiperConfig.swipeToMove; //  how much skiped
      
      console.log(endSlide, this.slideAmount)

      if (endSlide >= 0) {
        this.translate += (this.slidesWidth.slice(endSlide, this.currentSlide).reduce((acc, val) => acc + val, 0) + this.swiperConfig.swipeToMove * parseInt(this.swiperConfig.gap));
        this.currentSlide = endSlide;

        this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transition', 'transform 0.3s ease-in-out');
        this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);

      } else {
        this.translate -= (this.slidesWidth.slice(this.slideAmount - this.swiperConfig.swipeToMove, this.slideAmount).reduce((acc, val) => acc + val, 0) + this.swiperConfig.swipeToMove * parseInt(this.swiperConfig.gap));
      
        this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transition', 'none');
        this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);

        this.moveItemsToFront(this.swiperConfig.swipeToMove);

        setTimeout(() => {
          this.translate += (this.slidesWidth.slice(this.currentSlide, this.swiperConfig.swipeToMove).reduce((acc, val) => acc + val, 0) + this.swiperConfig.swipeToMove * parseInt(this.swiperConfig.gap));
          this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transition', 'transform 0.3s ease-in-out');
          this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);
        }, 10);        
      }
    }
  }

  swipeToBack() {
    if (this.swiperConfig.swipeToMove) {
      const endSlide = this.currentSlide + this.swiperConfig.swipeToMove; //  how much skiped
      
      console.log(endSlide, this.slideAmount)

      if (endSlide <= this.slideAmount - this.swiperConfig.swipeToMove) {
        this.translate -= (this.slidesWidth.slice(this.currentSlide, endSlide).reduce((acc, val) => acc + val, 0) + this.swiperConfig.swipeToMove * parseInt(this.swiperConfig.gap));
        this.currentSlide = endSlide;

        this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transition', 'transform 0.3s ease-in-out');
        this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);

      } else {
        this.translate += (this.slidesWidth.slice(0, this.swiperConfig.swipeToMove).reduce((acc, val) => acc + val, 0) + this.swiperConfig.swipeToMove * parseInt(this.swiperConfig.gap));
      
        this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transition', 'none');
        this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);

        this.moveItemsToBack(this.swiperConfig.swipeToMove);

        setTimeout(() => {
          this.translate -= (this.slidesWidth.slice(this.currentSlide, endSlide).reduce((acc, val) => acc + val, 0) + this.swiperConfig.swipeToMove * parseInt(this.swiperConfig.gap));
          this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transition', 'transform 0.3s ease-in-out');
          this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);
        }, 10);        
      }
    }
  }

  /* ------------------------------------------------------------------ */

  setConfig(swiperConfig: SwiperConfig) {
    console.log("swiperConfig =>", swiperConfig)

    if (swiperConfig) {
      this.renderer.setStyle(this.swiperContainer.nativeElement, 'width', swiperConfig.width);
      this.renderer.setStyle(this.swiperContainer.nativeElement, 'height', swiperConfig.swiperContainerHeight);
      this.renderer.setStyle(this.swiperWrapper.nativeElement, 'height', swiperConfig.swiperWrapperHeight);
      this.renderer.setStyle(this.swiperWrapper.nativeElement, 'gap', swiperConfig.gap);
      this.renderer.setStyle(this.swiperWrapper.nativeElement, 'align-items', swiperConfig.alignItems);
    }
  }

  calculateTotalWidth() {
    const slides = this.swiperWrapperElement.nativeElement.children;
    let totalWidth = 0;
    this.slidesWidth = [];
  
    for (let i = 0; i < slides.length; i++) {
      const slide = slides[i];
      const slideWidth = slide.getBoundingClientRect().width;
  
      totalWidth += slideWidth;
      this.slidesWidth.push(slideWidth);
    }
  
    totalWidth += (slides.length - 1) * parseFloat(this.swiperConfig.gap);
  
    this.slideAmount = slides.length;
    this.totalSlidesWidth = Math.round(totalWidth);
    console.log('Total width of slides:', this.totalSlidesWidth);
    console.log('Total width of slides:', this.slidesWidth);
  }

  checkImagesLoaded() {
    const images = this.swiperWrapper.nativeElement.querySelectorAll('img');
    const imagePromises = Array.from(images).map((img: HTMLImageElement) => {
      return new Promise<void>((resolve, reject) => {
        if (img.complete) {
          resolve();
        } else {
          img.onload = () => resolve();
          img.onerror = () => reject();
        }
      });
    });

    Promise.all(imagePromises).then(() => {
      this.calculateTotalWidth();
    }).catch(() => {
      console.error('One or more images failed to load.');
      this.calculateTotalWidth();
    });
  }

}
