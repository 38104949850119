import { Attribute, Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

export const MAIN_COLOR = '#FFD36C';
export const ACCENT_COLOR = '#FF9533';
export const WARN_COLOR = '#FFD36C';
export const BLACK_COLOR = '#000';
export const RED_COLOR = '#CF142B';
export const DARK_MAIN_COLOR = '#1C2827';

@Directive({ selector: '[round-button]' })
export class RoundButton implements OnInit {

    private commands: any[] = [];
    filled: boolean = false;
    cssColor: string;
    sizeClass:string = 'medium';
    cssTextColor = undefined;

    constructor(
        private renderer: Renderer2,
        private el: ElementRef,
        @Attribute('filled') filled = 'filled',
        @Attribute('color') color: string,
        @Attribute('size') size: string
    ) {
        this.sizeClass = size?size:'medium';
        let cssColor = MAIN_COLOR;

        switch (color) {
            case 'main': {
                cssColor = MAIN_COLOR;
                break;
            }
            case 'accent': {
                cssColor = ACCENT_COLOR;
                break;
            }
            case 'warn': {
                cssColor = WARN_COLOR;
                break;
            }
            case 'black': {
                cssColor = BLACK_COLOR;
                break;
            }
            case 'red': {
                cssColor = RED_COLOR;
            }
            case 'dark': {
              cssColor = DARK_MAIN_COLOR;
              this.cssTextColor = "#ffd46c";
          }
            default: {
                //statements;
                break;
            }
        }

        this.filled = filled === 'filled' ? true : false;
        this.cssColor = cssColor;
    }

    ngOnInit() {
        this.renderer.addClass(this.el.nativeElement, 'round-btn');
        this.renderer.addClass(this.el.nativeElement, this.sizeClass);
        if (this.filled) {
            this.renderer.setStyle(this.el.nativeElement, 'background-color', this.cssColor);
            this.renderer.addClass(this.el.nativeElement, 'filled');
            if (this.cssTextColor) {
              this.renderer.setStyle(this.el.nativeElement, 'color', this.cssTextColor);
            }
        }
        else {
            this.renderer.setStyle(this.el.nativeElement, 'border-color', this.cssColor);
            this.renderer.setStyle(this.el.nativeElement, 'color', this.cssColor);
        }

    }



}
