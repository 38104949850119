import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { searchGameTrx } from 'src/app/router-translation.labels';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { BasePageComponent } from '../../base-page/base-page.component';
import { GameCategory } from '../../../apollo/models/base-models';
import { getMappedCategoryLink } from '../../../router-mapping';
import { Router } from '@angular/router';

@Component({
  selector: 'app-desktop-category-menu',
  templateUrl: './desktop-category-menu.component.html',
  styleUrls: ['./desktop-category-menu.component.scss']
})
export class DesktopCategoryMenuComponent extends BasePageComponent {
  searchInput = new FormControl('', []);
  searchPlaceHolder = $localize`:@@search-game-placeholder:Search for a game...`;
  routeGame = searchGameTrx;
  @Input() showInput: boolean;
  @Input() setInput:string;
  @Output() searchQuery = new EventEmitter();
  constructor(
    protected translationConfig: TranslationConfig,
    private router: Router
  ) { super() }

  public searchGameTrx = this.translationConfig.getTranslation(searchGameTrx); // public??

  ngOnInit(): void {
    this.searchInput.setValue(this.setInput)
  }

  emitSearch(search: string) {
    this.searchQuery.emit(search);
  }

  onCategoryClick(category: GameCategory) {
    return getMappedCategoryLink(category, this.translationConfig);
  }

}
