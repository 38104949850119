import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, catchError, filter, forkJoin, Observable, of, tap } from 'rxjs';
import { HOST_NAME } from 'src/app/app-routing.module';
import { test_userDocumentsResponce, typeMapping, UserDocumentsResponse, DocumentData, Status, InputFile, TranslatedStatus, SectionCard, SectionCardDocument, SectionCardFile, SectionCardCookie, baseDocumentCookie } from './upload-documents.models';
import { CustomEncoder } from 'src/app/interceptors/encode-http.interceptor';
import { DOWNLOAD_DOCUMENT, GET_DOCUMENT, UPLOAD_DOCUMENTS, USER_DOCUMENTS } from '../http/base-http.service';
import { BaseUsermgrService } from '../http/base-usermgr.service';

@Injectable({
  providedIn: 'root'
})
export class UploadDocumentsService extends BaseUsermgrService {

  private userDocumentsResponse = new BehaviorSubject<UserDocumentsResponse>(null);
  private sectionCardsSubject = new BehaviorSubject<SectionCard[]>(null);

  constructor(
    http: HttpClient,
    cookie: CookieService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(HOST_NAME) public hostName,
  ) {
    super(http, cookie, locale, hostName);

    //localStorage.clear()
  }

  saveSectionCardsCookie(section_card_cookie: SectionCardCookie): void {
    localStorage.setItem('sectionCardsCookie', JSON.stringify(section_card_cookie));
  }

  getSectionCardsCookie(): SectionCardCookie | null {
    return localStorage.getItem('sectionCardsCookie') ? JSON.parse(localStorage.getItem('sectionCardsCookie')) : baseDocumentCookie;
  }

  fillSectionCookie(sectionCards: SectionCard[]) {
    const section_card_cookie = this.getSectionCardsCookie();

    if (section_card_cookie) {
      console.log('section_card_cookie', section_card_cookie);

      sectionCards.forEach((section) => {
        const key = Object.keys(typeMapping).find(k => typeMapping[k] === section.type);

        if (section_card_cookie[key].length > 0) {
          const section_documents: SectionCardDocument[] = [];

          section_card_cookie[key].forEach((cookie_elem) => {
            console.log('cookie_elem', cookie_elem);
            
            if (cookie_elem) {
              const last_doc = section.card_documents.find((document) => document.name === cookie_elem.name);
  
              if (last_doc) {
                section_documents.push(last_doc);
              }
  
            } else {
              section_documents.push(section.card_documents[0])
            }
  
          });

          section.card_documents = section_documents;

        } else {
          if (section.card_documents.length > 0) {
            section.card_documents = section.card_documents.slice(0, 1);
          }

        }

        section.card_files.forEach((card_file) => {
          const cookie_elem = section_card_cookie[key].find((elem) => elem.id === card_file.index);

          if (cookie_elem) {
            const document = section.card_documents.find((elem) => elem.index === cookie_elem.id);
            //console.log('document', document);
            if (cookie_elem.status === section.status) {
              //console.log('status same');
              card_file.stage = cookie_elem.stage;

            } else if (document) {
              if (document.status === this.translateStatus(Status.Pending)) {
                card_file.stage = -1;
              }

            }
            

          } else {
            console.log('cookie_elem isnt exist')
          }

        });

      });

      return sectionCards;

    } else {
      return sectionCards;
    }

  }

  updateSectionCards(sectionCards: SectionCard[]) {
    this.sectionCardsSubject.next(sectionCards);
  }

  getSectionCards() {
    return this.sectionCardsSubject.asObservable().pipe(filter((sectionCards) => sectionCards !== null));
  }

  fillSectionCards(sectionCards: SectionCard[], userDocuments: UserDocumentsResponse) {
    //  userDocuments = test_userDocumentsResponce;   //  debug
    sectionCards.forEach((section) => {
      const key = Object.keys(typeMapping).find(k => typeMapping[k] === section.type);
    
      if (key && userDocuments.status.hasOwnProperty(key)) {
        const { documents, status, hide_not_required } = userDocuments;

        //console.log(documents, status, hide_not_required);

        if (Array.isArray(documents[key])) {
          section.card_documents = documents[key].map((document, index) => ({
            index: index,
            status: this.translateStatus(document.status as Status),
            name: document.name,
            created: document.created
          })); 
        }

        section.is_hide = hide_not_required[key];
        section.status = this.translateStatus(status[key] as Status);

      }
    });

    return sectionCards;

  }

  public updateUserDocuments(): void {
    this.getDocuments().pipe(
      tap((userDocumentsResponse) => this.userDocumentsResponse.next(userDocumentsResponse)),
      catchError(error => {
        console.error('Error fetching user documents', error);
        return of(null);
      })
    ).subscribe();
  }

  public getUserDocuments(): Observable<UserDocumentsResponse> {
    return this.userDocumentsResponse.asObservable().pipe(filter((userDocuments) => userDocuments !== null));
  }

  /* used */
  public fillDocumentData(documentData: DocumentData, userDocumentsResponse: UserDocumentsResponse): DocumentData {
    const userDocumentData = { ...documentData };
    // userDocumentsResponce = test_userDocumentsResponce; //

    userDocumentData.sections.forEach(section => {
      const key = Object.keys(typeMapping).find(k => typeMapping[k] === section.type);
    
      if (key && userDocumentsResponse.status.hasOwnProperty(key)) {
        const { documents, status, hide_not_required: hide } = userDocumentsResponse;
    
        if (Array.isArray(documents[key])) {
          section.documents = documents[key].map(doc => ({
            ...doc,
            created: doc.created,
            name: doc.name,
            status: this.translateStatus(doc.status as Status),
            status_display: doc.status_display
          }));

        }

        section.disable = status[key] === Status.Approved || status[key] === Status.NotRequired /* || status[key] === Status.Pending */;
        section.status = this.translateStatus(status[key] as Status); /* section.status = status[key]; */
        section.hide = status[key] === Status.NotRequired;
        section.attempt = section.documents ? section.documents.length : 0;

      }
      
    });
    
    return userDocumentData;
  }

  createFileUploadHeaders(headers: {
    [name: string]: string | string[];
  } = {}): HttpHeaders {
    const newHeaders = {};
    Object.assign(newHeaders, headers);
    const csrfToken = this.cookie.get('csrftoken');
    newHeaders['Content-language'] = this.locale;
    newHeaders['Accept-Language'] = `pl-PL,${this.locale}`;
    newHeaders['x-translation-lang'] = this.locale?.slice(0, 2);
    if (csrfToken) {
      newHeaders['X-CSRFToken'] = csrfToken;
    }
    return new HttpHeaders(newHeaders);
  }

  uploadDocument(card_file: SectionCardFile) {
    const docForm = new FormData();
    const file = card_file.file;

    docForm.append('document_type', card_file.type);
    docForm.append('file-1', file);

    const httpOptions = {
      headers: this.createFileUploadHeaders(),
      withCredentials: true
    };

    //console.log(docForm.get('file-1'), docForm.get('document_type'));
    //return of(true)
    return this.http.post<any>(this.apiUrl + UPLOAD_DOCUMENTS, docForm, httpOptions);
  }

  getDocuments() {
    return this.get<any>(USER_DOCUMENTS);
  }

  getDocument(docId: string) {
    const url = GET_DOCUMENT + docId
    return this.get<any>(url)
  }

  downloadDocument(docId: string) {
    const url = DOWNLOAD_DOCUMENT + docId
    return this.get<any>(url)
  }

  // Function to map Status to TranslatedStatus
  translateStatus(status: Status): string {
    const translationMap: { [key in Status]: string } = {
      [Status.NotRequired]: TranslatedStatus.NotRequired,
      [Status.Required]: TranslatedStatus.Required,
      [Status.Approved]: TranslatedStatus.Approved,
      [Status.Pending ]: TranslatedStatus.Pending,
      [Status.Rejected]: TranslatedStatus.Rejected,
      [Status.Expired]: TranslatedStatus.Expired,
    };

    return translationMap[status];
  }

}
