import { Apollo } from 'apollo-angular';
import { Component, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { SystemPage, SystemPageResponse } from 'src/app/apollo/models/base-models';
import { SYSTEM_PAGE_CONTENT_QUERY } from 'src/app/apollo/static-page';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { RedeemCodeService } from 'src/app/services/client-area/redeem-code.service';
import { REDEEM_CODE_STATUS_VERIFY, RedeemCodeResponse } from 'src/app/services/client-area/client-area.models';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { SignupVerifySmsComponent } from 'src/app/components/auth/signup/signup-verify-sms/signup-verify-sms.component';
import { RegistrationService } from 'src/app/services/signup/registration-fields.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginSmsVerificationService } from 'src/app/services/auth/login/login-sms-verification.service';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { clientAreaTrx, bonusQueueTrx } from 'src/app/router-translation.labels';
import { TranslationConfig } from 'src/app/utils/translate-config';


@Component({
  selector: 'app-redeem-promocode',
  templateUrl: './redeem-promocode.component.html',
  styleUrls: ['./redeem-promocode.component.scss']
})
export class RedeemPromocodeComponent extends SignupVerifySmsComponent {

  page: SystemPage;
  body: SafeHtml;
  redeemFormSubmited: boolean = false;
  errorMsg: string;
  hasError: boolean = false;

  public bonusQueueUrl = ["", this.translationConfig.getTranslation(clientAreaTrx), this.translationConfig.getTranslation(bonusQueueTrx)];

  redeemCodeResponse: RedeemCodeResponse;
  readonly genericError: string = $localize`:@@promo-code-error:There was some error adding promocode.`;



  redeemCodeForm = this.formBuilder.group({
    code: ['', [Validators.required]],
  });

  constructor(private apollo: Apollo,
    protected formBuilder: UntypedFormBuilder,
    protected redeemCodeService: RedeemCodeService,
    public detectDeviceService: DetectDeviceService,
    protected route: ActivatedRoute,
    private translationConfig: TranslationConfig,
    dialog: MatDialog,
    @Inject(LOCALE_ID) public locale: string,
    protected licenceService: LicenceService,
    protected registrationService: RegistrationService,
    protected loginWithSms: LoginSmsVerificationService,
    protected loginStatusService: LoginStatusService,
    protected renderer: Renderer2,
    protected sanitizer: DomSanitizer,) {
    super(formBuilder,
      registrationService,
      dialog,
      detectDeviceService,
      loginWithSms,
      renderer,
      licenceService
    )
  }

  ngOnInit(): void {
    this.isPromocdeFlow = true;
    this.isDesktop = this.detectDeviceService.isDesktop();
    // this.isSmsVerification = true;
    this.loginStatusService.getIfUserLogged().pipe(
      take(1),
    ).subscribe(resp => {
      this.telephone = resp.fullInfo.telephone.startsWith('+') ? resp.fullInfo.telephone : '+' + resp.fullInfo.telephone;
      this.apollo.watchQuery<SystemPageResponse>({
        query: SYSTEM_PAGE_CONTENT_QUERY,
        variables: {
          pageName: "redeem-promocode",
          locale: this.locale
        }
      }).valueChanges.pipe(take(1),).subscribe((resp) => {
        this.page = resp.data.systemPage;
        this.body = this.sanitizer.bypassSecurityTrustHtml(this.page.content.html);
      }, (error) => {

      });
      const code = this.route.snapshot.paramMap.get('code');
      if (code) {
        this.getCode(code);
      }
    })


  }

  onSubmit(redeemCodeForm) {
    if (this.redeemFormSubmited || !redeemCodeForm.valid) return;
    this.errorMsg = null;
    this.redeemFormSubmited = true;
    this.redeemCodeService.requestRedeemCode(redeemCodeForm).pipe(takeUntil(this.unsubscribe)).subscribe((resp: RedeemCodeResponse) => {
      this.redeemCodeResponse = resp;
      if (resp.status !== REDEEM_CODE_STATUS_VERIFY) {
        this.isReedeemCompleted = true;
      }
      else {
        this.isSmsVerification = true
      }
    }, err => {
      this.redeemFormSubmited = false;
      if (err.status === 400) {
        this.errorMsg = !!err.error.errors?.code ? err.error.errors.code : this.genericError;
        this.hasError = true;
        this.openErrorDialog(this.errorMsg)
      } else {
        this.openErrorDialog();
      }
    });
  }

  onSmsSuccessVerification(resp): void {

  }

  onSmsSubmit(smsForm) {
    super.onSubmit(smsForm, this.onSmsSuccessVerification);
  }

  getCode(code: any) {
    this.redeemCodeForm.controls.code.setValue(code);
  }
  closeDialog(): void {
    this.isSmsVerification = true;
    this.pageView = null;
    this.isChangePhone = false;
  }
  openModal() {
  }
  changePhoneNumber(){}
}
