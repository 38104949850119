<!-- <div class="game-container">
    <div *ngIf="data" fxLayoutAdivgn="center start" fxLayout="row" class="game-grid">
        <div class="thumbnail"><img alt="{{data.name}}" src="{{ data.thumbnail?.url || data?.thumbnailDesktop?.url }}"/></div>

        <div fxLayoutAdivgn="center start" class="game-title h16">
            {{data.name}}
        </div>

        <div class="action-icon">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </div>
    </div>
</div> -->

<div class="game-card" (click)="onOpenGame(game)">
    <ng-container *ngIf="game.thumbnail?.url; else desktop">
        <img alt="{{game.name}}" src="{{ game.thumbnail.url }}" />
    </ng-container>

    <ng-template #desktop>
        <img alt="{{game.name}}" src="{{ game.thumbnailDesktopSquare.url }}" />
    </ng-template>
    <p style="padding-top: 0.25rem;">{{game.name}}</p>
</div>