<div class="wrapper">
  <div class="header-wrapper">
    <div class="header">
      <img class="header-icon" src="assets/icons/categories/icon-my-games.svg" alt="icon-my-games"/>
      <div class="header-text" i18n>My Games</div>
    </div>

    <div *ngIf="favouriteService.isRefreshed && lastPlayedService.isRefreshed">
      <div class="tabs">
        <div [class]="(activeTab === 'favourites') ? ['tab', 'active'] : ['tab']" (click)="switchTab('favourites')">
          <img class="tab-icon" src="assets/icons/icon-favourites.svg"/>
          <div class="tab-text" i18n>Favourites</div>
        </div>

        <div [class]="(activeTab === 'last-played') ? ['tab', 'active'] : ['tab']" (click)="switchTab('last-played')">
          <img class="tab-icon" src="assets/icons/categories/icon-most-played.svg"/>
          <div class="tab-text" i18n>Last played</div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="favouriteService.isRefreshed && lastPlayedService.isRefreshed else loading">
    <div *ngIf="activeTab === 'favourites'">
      <div class="game-card-wrapper" *ngIf="favouriteGames.length > 0 else noFavouriteGames">
        <ng-container *ngFor="let item of favouriteGames">
          <div class="game-card">
            <app-game-card [isOutsideCategory]="isOutsideCategory" (clickEvent)="openDialog($event)" [data]="item"></app-game-card>
          </div>
        </ng-container>
      </div>
      <ng-template #noFavouriteGames>
        <div class="no-favourite-games">
          <h4 i18n>Your List is empty...</h4>
          <div i18n>
            Show some love to your favourite games. <br/>Hit “Add to  Favourites” button from the game screen.</div>
          <div class="icon" style="mask-image: url('/assets/icons/icon_fav_add_40x40.svg')"></div>
        </div>
      </ng-template>
    </div>

    <div *ngIf="activeTab === 'last-played'">
      <div class="game-card-wrapper" *ngIf="lastPlayedGames.length > 0 else noLastPlayedGames">
        <ng-container *ngFor="let item of lastPlayedGames">
          <div class="game-card">
            <app-game-card [isOutsideCategory]="isOutsideCategory" (clickEvent)="openDialog($event)" [data]="item"></app-game-card>
          </div>
        </ng-container>
      </div>
      <ng-template #noLastPlayedGames>
        <div class="no-favourite-games">
          <h4 i18n>Your List is empty...</h4>
          <div i18n>You haven't played yet. <br/>Have some fun with our games.</div>
        </div>
      </ng-template>
    </div>
  </div>
  <ng-template #loading>
    <div class="loading">
      <mat-spinner diameter="32"></mat-spinner>
    </div>
  </ng-template>

</div>
