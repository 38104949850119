import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { SearchConfigFilterItem, SearchGameService } from 'src/app/services/games/search-game.service';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';

@Component({
  selector: 'app-search-filter-sheet',
  templateUrl: './search-filter-sheet.component.html',
  styleUrls: ['./search-filter-sheet.component.scss']
})
export class SearchFilterSheetComponent extends BasePageComponent implements OnInit {

  public isSelected: boolean = false;
  public isDesktop: boolean;

  public filterItemDisplay: SearchConfigFilterItem;
  public filterItems: SearchConfigFilterItem[];

  public filterItemName: string;
  @Input() _filterItemName: string;
  
  @Output() closeEvent = new EventEmitter<null>();

  constructor(
    private searchGamesService: SearchGameService,
    public detectDeviceService: DetectDeviceService,
  ) {
    super();

    this.isDesktop = detectDeviceService.isDesktop();
  }

  ngOnInit(): void {
    this.filterItemName = this._filterItemName;

    this.searchGamesService.searchFilters$.pipe(takeUntil(this.unsubscribe)).subscribe((filterItems) => {
      this.filterItems = filterItems;
      this.filterItemDisplay = filterItems.find((filterItem) => filterItem.name === this.filterItemName); //  sort data in filterItemDisplay.data[array].name

      if (this.filterItemDisplay) this.filterItemDisplay.data.sort((a, b) => a.name.localeCompare(b.name));

      this.isSelected = this.checkIfAnyFilterItemSelected();
    })
  }

  onCheckboxChange() {
    this.filterItems = this.filterItems.map((filterItem) => {
      if (filterItem.name === this.filterItemName) {
        this.filterItemDisplay.activeFilters = this.filterItemDisplay.data.reduce((count, item) => item.selected ? count + 1 : count, 0);

        return this.filterItemDisplay
      }

      return filterItem;
    });

    this.isSelected = this.checkIfAnyFilterItemSelected();
  }

  onCloseEvent() {
    this.searchGamesService.setSearchFilters(this.filterItems);
    this.closeEvent.next(null);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event) {
    const footer = document.querySelector('.filter-sheet-footer') as HTMLElement;
    const body = document.querySelector('.filter-sheet-body') as HTMLElement;
    const confirmBtn = document.querySelector('.confirm-btn') as HTMLElement;

    if (body && footer) {
      const bodyRect = body.getBoundingClientRect();
      const footerRect = footer.getBoundingClientRect();

      if (bodyRect.bottom <= window.innerHeight - 150) {
        footer.style.position = 'relative';
        footer.style.bottom = '0';
        footer.style.height = 'auto';
        footer.style.backdropFilter = 'none';
        confirmBtn.style.margin = '0'
      } else {
        footer.style.position = 'fixed';
        footer.style.bottom = '55px';
        footer.style.height = '120px';
        footer.style.backdropFilter = 'blur(3px)';
        confirmBtn.style.marginBottom = '0.5rem'
      }
    }
  }

  private checkIfAnyFilterItemSelected(): boolean {
    if (!this.filterItemDisplay || !this.filterItemDisplay.data) {
      return false;
    }

    return this.filterItemDisplay.data.some((item) => item.selected);
  }

}
