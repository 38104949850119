import { Apollo, gql } from 'apollo-angular';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { LoginStatusService } from '../auth/login/login-status.service';

import { CommonTournament, NetworkTournament, PageDisplayTournament, PlayerTournamentInfo, Tournament, TournamentGame, TOURNAMENTTYPE } from './tournaments.models';
import { REBUY_BONUS_TOURNAMENTS } from '../http/base-http.service';
import { HOST_NAME, LICENCE } from 'src/app/app-routing.module';
import { filter, map, switchMap, take, tap, timestamp } from 'rxjs/operators';
import { BonusQueueService } from '../wallet/bonus-queue.service';
import { InAppPageParams } from 'src/app/apollo/models/base-models';
import { LoggedStatus } from '../auth/login/login.models';
import { FilterGamesService } from '../utils/filter-games.service';
import { ClonerService } from '../utils/clone-object.service';
// import { tag } from 'rxjs-spy/cjs/operators';

const query = gql`query PageTournamentDisplay($locale: Locale!){
  tournamentPageDisplays{
    tournamentType
    tournament(locales: [$locale, en]){
      id
      name
    }
  }
}
`

const pageTournamentDisplayQuery = gql`query PageTournamentDisplay($locale: Locale!, $licenseName: Licence!){
  tournamentPageDisplays{
    startDate
    endDate
    tournamentType
    isPrivate
    minBetCurrencies
    rebuyPriceCurrencies
    minBet
    ref
    bonusRef
    tournamentRebuyAddPoints
    tournamentRebuyBonusId
    tournamentMaximumRebuys
    tournamentRebuyPrice
    tournament(locales: [$locale, en]){
      id
      name
      licence
      games{
        name
        gameid
        systemName
        gameProvider{
            name
            providerExclusionTerritories{
              countryCode
              state
          }
        }
        thumbnail{
          url(transformation: {
              image: { resize: { width: 206, height: 206, fit: clip } }

            })
        }
        thumbnailDesktopSquare{
          url(transformation: {
            image: { resize: { width: 206, height: 206, fit: clip } }
            document: { output: { format: webp } }
          })
        }
        thumbnailDesktopRectangular{
          url(transformation: {
            image: { resize: { width: 236, height: 177, fit: clip } }
            document: { output: { format: webp } }
          })
        }

      }
      displayBonusName
      streamingChannelName
      tournamentRebuy{
        price
        maximumRebuys
        bonusId
        rebuyAddPoints
      }
      thumbnailMobile{
          url(transformation: {
              image: { resize: { width: 394, height: 128, fit: clip } }

            })
        }
      thumbnailDesktop{
        url(transformation: {
            image: { resize: { width: 404, height: 176, fit: crop } }

          })
      }
      largeDesktopThumbnail{
        url(transformation: {
            image: { resize: { width: 826, height: 233, fit: clip } }

          })
      }
      largeDesktopBanner: largeDesktopThumbnail{
        url(transformation: {
          image: {resize: {width:1248, height: 368, fit:clip}}
        })
      }
      seo {
          metaTitle
          metaDescription
          relCanonical
      }
    }
  }
  inAppPagesParams(where: {AND: {siteName:"tournaments-all", OR: [{licence_contains_some: [all,$licenseName]}] }}){
    seo(locales: [$locale, en]) {
          metaTitle
          metaDescription
          relCanonical
      }
  }
}
`

const queryNetworkTournaments = gql`query NetworkTournamentsList($locale: Locale!){
  tournamentsNetwork( locales: [$locale, en]) {
    cmsId:id
    name
    startDate
    endDate
    games{
        name
        gameid
        systemName
        gameProvider{
            name
            providerExclusionTerritories{
              countryCode
              state
          }
        }
        thumbnail{
          url(transformation: {
              image: { resize: { width: 206, height: 206, fit: clip } }

            })
        }
        thumbnailDesktopSquare{
          url(transformation: {
            image: { resize: { width: 206, height: 206, fit: clip } }
            document: { output: { format: webp } }
          })
        }
        thumbnailDesktopRectangular{
          url(transformation: {
            image: { resize: { width: 236, height: 177, fit: clip } }
            document: { output: { format: webp } }
          })
        }

      }
    thumbnailMobile{
        url(transformation: {
            image: { resize: { width: 394, height: 128, fit: clip } }

          })
      }
      thumbnailDesktop{
        url(transformation: {
            image: { resize: { width: 405, height: 233, fit: clip } }

          })
      }
      largeDesktopThumbnail{
        url(transformation: {
            image: { resize: { width: 826, height: 233, fit: clip } }

          })
      }
      largeDesktopBanner: largeDesktopThumbnail{
        url(transformation: {
          image: {resize: {width:1248, height: 368, fit:clip}}
        })
      }
  }
  }
  `
const localStorageKeyPostfix = '_tournament_tabIndex';

@Injectable({
  providedIn: 'root'
})
export class TournamentService extends BaseFirebaseService {

  constructor(
    http: HttpClient,
    afAuth: AngularFireAuth,
    afs: AngularFirestore,
    private apollo: Apollo,
    private bonusQueueService: BonusQueueService,
    private loginStatus: LoginStatusService,
    private filterGameService: FilterGamesService,
    private cloneService: ClonerService,
    @Inject(HOST_NAME) public hostName,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(LICENCE) public licence: string,
  ) {
    super(http, afAuth, afs, hostName);
  }

  private _tournaments = new BehaviorSubject<Array<CommonTournament>>([]);
  private _inAppTournamentMetaData = new BehaviorSubject<InAppPageParams>(null);
  private privateTournaments: Tournament[] = [];
  private isStoreInitiated = false;

  public testQuery() {
    return this.apollo
      .watchQuery({
        query: query,
        variables: {
          locale: this.locale,
          licenseName: this.licence,
          // timestamp: new Date().getTime().toString()
        }
      })
      .valueChanges.pipe(take(1))
  }

  public updateTournamentsStore(): Observable<Array<Tournament | NetworkTournament>> {
    this.isStoreInitiated = true;
    return combineLatest({

      regularTournament: this.apollo
        .watchQuery<{
          tournamentPageDisplays: PageDisplayTournament[],
          inAppPagesParams: InAppPageParams[]
        }>({
          query: pageTournamentDisplayQuery,
          variables: {
            locale: this.locale,
            licenseName: this.licence,
            // timestamp: new Date().getTime().toString()
          }
        })
        .valueChanges.pipe(

          tap(item => {
            const inAppparams = item.data.inAppPagesParams?.filter(item => !!item.licence?.find(licence => licence === this.licence))[0] || item.data.inAppPagesParams[0]
            this._inAppTournamentMetaData.next(inAppparams)
          }),
          map(item => item.data.tournamentPageDisplays.filter(item => !!item.tournament && item.tournament?.licence.find(elem => elem === this.licence || elem === 'all'))),
          map(items => {
            const result: Tournament[] = [];
            this.privateTournaments = [];
            for (let tournamentPageDisplay of items) {
              if (!tournamentPageDisplay.tournament) continue;
              const tournament: Tournament = { isNetworkTournament: false, ...tournamentPageDisplay.tournament, ...tournamentPageDisplay, games: this.filterGameService.filterGames(tournamentPageDisplay.tournament.games) as TournamentGame[], cmsId: tournamentPageDisplay.tournament.id, tournamentType: TOURNAMENTTYPE[tournamentPageDisplay.tournamentType] };
              if (!tournament.isPrivate) {
                result.push(tournament);
              } else {
                this.privateTournaments.push(tournament);
              }
            }
            result.sort((a, b) => {
              if (a.tournamentType === TOURNAMENTTYPE.ACTIVE && b.tournamentType === TOURNAMENTTYPE.UPCOMMING) return -1;
              else if (a.tournamentType === TOURNAMENTTYPE.UPCOMMING && b.tournamentType === TOURNAMENTTYPE.UPCOMMING) return 0;
              else if (a.tournamentType === TOURNAMENTTYPE.ACTIVE && b.tournamentType === TOURNAMENTTYPE.ACTIVE) return 0;
              else if (a.tournamentType === TOURNAMENTTYPE.UPCOMMING && b.tournamentType === TOURNAMENTTYPE.ACTIVE) return 1;
              else if (b.tournamentType === TOURNAMENTTYPE.HISTORY) return -1;
            })
            return result;
          }),
          // tap(item => console.log(item)),
        ),
      networkTournaments: this.getNetworkTournaments()
    }).pipe(
      map(resp => {
        const endDate = new Date(new Date().getTime() + (2 * 60 * 60 * 1000));
        const firstNonActiveTournament = Math.abs(resp.regularTournament.findIndex(item => item.tournamentType !== TOURNAMENTTYPE.ACTIVE));
        const activeNetwrok = resp.networkTournaments.filter(x => new Date(x.endDate) > endDate);
        const result = [...resp.regularTournament.slice(0, firstNonActiveTournament), ...activeNetwrok, ...resp.regularTournament.slice(firstNonActiveTournament)];
        this._tournaments.next(result);

        return result;
      })
    )

  }

  public getTournaments() {
    return this.isStoreInitiated ? this._tournaments.asObservable() : this.updateTournamentsStore();
  }

  public getInAppTournamentMetaData() {
    return this._inAppTournamentMetaData.asObservable().pipe(
      filter(item => !!item)
    );
  }

  public getNetworkTournaments() {
    return this.apollo
      .watchQuery<{ tournamentsNetwork: NetworkTournament[] }>({
        query: queryNetworkTournaments,
        variables: {
          locale: this.locale,
        }
      })
      .valueChanges.pipe(
        map(item => item.data.tournamentsNetwork),
        map(item => {
          const result = []
          for (let tournament of item) {
            let clontTournament = this.cloneService.deepClone<NetworkTournament>(tournament);
            clontTournament.isNetworkTournament = true;
            result.push(clontTournament);
          }
          return result;
        })
      )
  }

  public getPrivateTournaments() {
    return this.loginStatus.getLoginStatus().pipe(
      filter(status => status.isLogged !== LoggedStatus.voidState),
      switchMap(status => {
        if (status.isLogged === LoggedStatus.logged) {
          return this.bonusQueueService.getBonusList().pipe(
            take(1),
            switchMap(bonusList => {
              const result = [...this._tournaments.getValue()];
              for (let privateTournament of this.privateTournaments) {
                if (bonusList.filter(bonus => bonus.bonus_ref === privateTournament.bonusRef).length > 0) {
                  result.unshift(privateTournament);
                }
              }
              this._tournaments.next(result);
              return this._tournaments.asObservable();
            })
          )
        } else {
          return of([])
        }
      })
    )
  }

  public forceUpdateStore() {
    this.updateTournamentsStore().pipe(take(1)).subscribe(() => { });
  }

  public getActiveTournamentForGameId(gameId: string): Observable<{ tournamentIndes: number, tournamentRef: string, tournament: CommonTournament }> {
    let index = -1;
    let ref = "";
    let tournament = null;
    return this.getTournaments().pipe(
      map(tournaments => {
        tournaments.filter(item => {
          return !item.isNetworkTournament && (item as Tournament).tournamentType === TOURNAMENTTYPE.ACTIVE
        }).forEach((item, itemIndex) => {
          const tournament = item as Tournament;
          if (item.games?.filter(game => game.gameid === gameId).length > 0) {
            index = itemIndex;
            ref = tournament.ref;
          }
        })
        return { tournamentIndes: index, tournamentRef: ref, tournament: tournaments[index] };
      })
    )
  }

  public getTournament(id: string) {
    return combineLatest(
      {
        regular: this.getTournaments(),
        // private: this.getPrivateTournaments()
      }).pipe(
        take(1),
        map(resp => {
          // let tournaments = [...resp.regular, ...resp.private];
          let tournaments = [...resp.regular];
          return tournaments.find(item => item.cmsId === id);
        })
      )
  }

  private getPlayerTournamentInfo(tournamentRef: string): Observable<PlayerTournamentInfo> {
    return this.loginStatus.getIfUserLogged().pipe(
      switchMap(userState => {
        console.log(userState.username)
        return this.getDocumentDB<PlayerTournamentInfo>(`backend-data-users/${userState.username}/tournaments/${tournamentRef}`);
      })
    )
  }

  public getRebuys(tournamentRef: string) {
    return this.getPlayerTournamentInfo(tournamentRef).pipe(
      map(resp => !!resp ? resp.numberRebuys : 0)
    )
  }

  public rebuyTournamentBonus(tournamentRef: string) {
    return this.post<{ tournamentRef: string }, any>(REBUY_BONUS_TOURNAMENTS, { tournamentRef: tournamentRef })
  }

  public saveTournamentTab(tournamentId: string, index: number) {
    localStorage.setItem(this.getLocalStorageKeyForTab(tournamentId), index.toString());
  }

  public getTournamentTab(tournamentId: string) {
    const index = localStorage.getItem(this.getLocalStorageKeyForTab(tournamentId));
    return index ? parseInt(index) : 0;
  }

  private getLocalStorageKeyForTab(tournamentId: string) {
    return tournamentId + localStorageKeyPostfix;
  }
}
