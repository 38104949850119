import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { SearchConfigFilterItem, SearchDataItem, SearchGameService } from 'src/app/services/games/search-game.service';
import { FilterGamesService } from 'src/app/services/utils/filter-games.service';

enum ChipType {
  CLEAR = 'clear',
}

interface ChipItem extends SearchDataItem {
  type?: ChipType,
  parent?: string
}

@Component({
  selector: 'app-search-filter-chips',
  templateUrl: './search-filter-chips.component.html',
  styleUrls: ['./search-filter-chips.component.scss']
})
export class SearchFilterChipsComponent extends BasePageComponent implements OnInit {

  public filterName: string;

  public chipsList: ChipItem[]
  public filterItems: SearchConfigFilterItem[];
  public ChipType = ChipType;

  private clearAllTxt = $localize`:@@search-game-chips-clear:Clear All`;

  @Input() _filterName: string = undefined;

  constructor(
    private searchGamesService: SearchGameService,
  ) {
    super()
  }

  ngOnInit() {
    this.filterName = this._filterName;

    this.searchGamesService.searchFilters$.pipe(takeUntil(this.unsubscribe)).subscribe((filterItems) => {
      this.filterItems = filterItems;

      if (this.filterName) {
        const filterItem = filterItems.find(item => item.name === this.filterName);

        if (filterItem) {
          const selectedItems: ChipItem[] = filterItem.data.filter((item) => item.selected === true).map((item) => ({ ...item, parent: filterItem.name }));

          if (selectedItems.length > 0) {
            const clearChip: ChipItem = { name: 'clear', displayName: this.clearAllTxt, selected: false, type: ChipType.CLEAR, parent: filterItem.name };
            selectedItems.push(clearChip);
          }

          this.chipsList = selectedItems;
        } else {
          this.chipsList = [];
        }
      } else {
        this.chipsList = filterItems.reduce((acc, filterItem) => {
          const selectedItems: ChipItem[] = filterItem.data.filter((item) => item.selected === true).map((item) => ({ ...item, parent: filterItem.name }));

          if (selectedItems.length > 0) {
            const clearChip: ChipItem = { name: 'clear', displayName: this.clearAllTxt, selected: false, type: ChipType.CLEAR, parent: filterItem.name };
            selectedItems.push(clearChip);
          }

          return acc.concat(selectedItems);
        }, []);
      }

      // console.log('SearchFilterChipsComponent, chipsList =>', this.chipsList);
    });
  }

  onChipRemove(chip: ChipItem) {
    this.chipsList = this.chipsList.filter(item => item !== chip);

    this.filterItems = this.filterItems.map((filterItem) => {
      if (chip.type === ChipType.CLEAR && filterItem.name === chip.parent) {
        filterItem.data = filterItem.data.map((item) => ({
          ...item,
          selected: false
        }));
      } else {
        filterItem.data = filterItem.data.map((item) => {
          if (item.name === chip.name) {
            return { ...item, selected: false };
          }
          return item;
        });
      }

      filterItem.activeFilters = filterItem.data.reduce((count, item) => item.selected ? count + 1 : count, 0)

      return filterItem;
    });

    this.searchGamesService.setSearchFilters(this.filterItems);
  }

  setChipList() {

  }

}

