import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HidePromotionsService {

  private showBanner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() { }

  isShowBanner() {
    return this.showBanner.asObservable();
  }

  toggleShowBanner() {
    this.showBanner.next(!this.showBanner.value);
  }

}
